import VueCookie from "vue-cookies";
import {version} from "../../package.json";

function getCookieVersion(){
    let v = VueCookie.get("v")
    if(!v)
        v = 0
    return parseInt(v)
}
export function getStringVersion(){
    return version
}
function getParsedVersion(){
    console.log(parseInt(version.split(".").join("")))
    return parseInt(version.split(".").join(""))
}
function isNewVersion(){
    return getCookieVersion()<getParsedVersion()
}
export function checkVersion(){
    const v = isNewVersion()
    if(v)
        VueCookie.set("v", getParsedVersion(), "1y")
    return v
}