<template>
  <div class="content">
    <md-app md-waterfall md-mode="overlap">
      <md-app-toolbar class="md-primary md-large">
        <div class="md-toolbar-row">
          <md-button class="md-icon-button" @click="menuVisible = !menuVisible">
            <md-icon>menu</md-icon>
          </md-button>

          <div class="md-title title">
            <span>Welcome, {{ user.name }}</span>
            <span>kaiser dashboard</span>
          </div>
        </div>
      </md-app-toolbar>

      <md-app-drawer v-bind:md-active="menuVisible">
        <md-toolbar class="md-transparent" md-elevation="0">
          <md-button class="md-icon-button" @click="menuVisible = !menuVisible" style="opacity:.7">
            <md-icon>arrow_back_ios</md-icon>
          </md-button>

        </md-toolbar>

        <md-list>
          <md-list-item class="mouse-style">
            <md-icon>insights</md-icon>
            <span class="md-list-item-text" @click="link({name:'Insights'})">Insights</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>group_work</md-icon>
            <span class="md-list-item-text " @click="link({name:'Matching Insights'})">Matching Insights</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>assessment</md-icon>
            <span class="md-list-item-text " @click="link({name:'Article Insights'})">Article Insights</span>
          </md-list-item>


          <md-list-item class="mouse-style">
            <md-icon>share</md-icon>
            <span class="md-list-item-text " @click="link({name:'Referral'})">Referral</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>translate</md-icon>
            <span class="md-list-item-text " @click="link({name:'Translate'})">Translate</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>article</md-icon>
            <span class="md-list-item-text " @click="link({name:'Content'})">Content</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>spa</md-icon>
            <span class="md-list-item-text " @click="link({name:'Challenge'})">Challenge</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>reviews</md-icon>
            <span class="md-list-item-text " @click="link({name:'Recommendation'})">Recommendation</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>folder</md-icon>
            <span class="md-list-item-text" @click="link({name:'Files'})">Files</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>people</md-icon>
            <span class="md-list-item-text " @click="link({name:'Team'})">Team</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>mail</md-icon>
            <span class="md-list-item-text " @click="link({name:'Mail'})">Mail</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>poll</md-icon>
            <span class="md-list-item-text " @click="link({name:'Others Table'})">Others Table</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>report_problem</md-icon>
            <span class="md-list-item-text " @click="link({name:'Reports'})">Reports</span>
          </md-list-item>

          <md-list-item class="mouse-style">
            <md-icon>logout</md-icon>
            <span class="md-list-item-text " @click="logout()">Logout</span>
          </md-list-item>
        </md-list>
      </md-app-drawer>

      <md-app-content>
        <router-view/>
      </md-app-content>
    </md-app>

    <md-dialog :md-active="showVersionPopup">
      <md-dialog-title>New Version: v{{ version }}</md-dialog-title>

      <md-dialog-content>
        <p>One new amazing Improvement:</p>
        <p>Our website now support ukrainian text. (to be translated)</p>
        <p>Two old amazing Improvements:</p>
        <h3>Tags</h3>
        <p>Bugfix: Tags are saved now for real.<br />
          Improvement: Tags can be edited.</p>
        <h3>Security</h3>
        <p>Everything is now encrypted and secured in a more fancy way.</p>
        <p>Ruben :)</p>
      </md-dialog-content>

      <md-dialog-actions>
        <md-button class="md-primary" @click="showVersionPopup = false">OK!</md-button>
      </md-dialog-actions>
    </md-dialog>

  </div>
</template>

<script>
import store from '@/store'
import {mapActions} from 'vuex';
import {checkVersion, getStringVersion} from "@/utils/version";
import {axiosGet} from "@/utils/axiosWrapper";

export default {
  data() {
    return {
      user: {
        name: "",
        email: ""
      },
      userId: store.state.accessToken,
      users: [],

      others: [],
      reports: [],

      menuVisible: false,

      showVersionPopup: checkVersion(),
      version: getStringVersion()
    }
  },
  methods: {
    ...mapActions([
      'logout'
    ]),
    loadData() {
      return axiosGet('/users/id/' + this.userId)
          .then(function (response) {
            return response.data
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    link(to) {
      this.menuVisible = false
      this.$router.push(to).catch(err => console.warn(`Seems we are already here: ${err}`))
    },
  },
  async mounted() {
    this.user = await this.loadData()
  },
  components: {}
}
</script>


<style scoped>
.content {
  text-align: left;
  color: #333;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

}

.welcome {
  margin-bottom: 1em;
}

.md-app {
  height: calc(100vh - 40px);
}

.md-drawer {
  width: 230px;
}

.mouse-style:hover {
  cursor: pointer;
}
</style>
